import React from 'react';
import FacebookSignInHandler from "../components/FacebookSignInHandler";
import { AuthProvider } from "../components/context/AuthContext";
import NoLayout from '../components/layouts/NoLayout';

const Facebook: React.FC = () => {
    return (
        <NoLayout>
            <AuthProvider>
                <FacebookSignInHandler />
            </AuthProvider>
        </NoLayout>
    );
};

export default Facebook;
