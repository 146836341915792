import React, { useContext, useEffect } from "react";
import { AuthContext } from "./context/AuthContext";
import axios from "../util/axios";
import { navigate } from "gatsby";
import { getUrlParams } from '../util/url';
import GlobalLoader from './GlobalLoader';
import styled from 'styled-components';

const Container = styled.div`
	height: 100vh;
	display: flex;
	align-items: center;
	flex-direction: column;
	justify-content: center;
`;

const FacebookSignInHandler: React.FC = () => {
    const { login } = useContext(AuthContext);

    useEffect(() => {
        const params = document.location.search;

        const facebookLogin = async () => {
            const url = '/facebook' + params;

            let response;

            try {
                response = await axios.get(url);
            } catch (err) {
                navigate('/login?error_code=500')
                return;
            }

            login(response.data.token);

            const urlParams: any = getUrlParams(document.location.search);

            if (urlParams.state) {
                navigate(decodeURIComponent(urlParams.state));
            } else {
                navigate('/');
            }
        }

        facebookLogin();
    }, []);

    return (
        <Container>
            <GlobalLoader text={'Authenticating...'}/>
        </Container>
    );
};

export default FacebookSignInHandler;
